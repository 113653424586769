import logo from './logo.png';
import './App.css';
import './satoshi.css';

function App() {
    return (
        <div className="App">
            <div className="Background">
                <img src={logo} className="Logo" alt="logo"/>
                <header className="my-header">
                    We’ll be back soon!
                </header>
                <p className="my-text">We are working hard to bring you latest updates on<br />
                    marking pheromones for safe & affordable pest control</p>
                <p className="my-footer">Contact us at: info@noovi.tech</p>
            </div>
        </div>
    );
}

export default App;
